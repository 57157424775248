<template>
  <v-row>
    <v-col cols="6">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>ID</th>
              <th>Имя</th>
              <th>Дата создания</th>
              <th>Автор</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(post, i) in posts" :key="i"
            >
              <td>{{post.id}}</td>
              <td>
                <router-link :to="{ path: '/ad/welcome/'+post.id}">{{post.title}}</router-link> <router-link :to="{ path: '/ad/welcome/'+post.id}" target="_blank"><v-icon small>{{mdiOpenInNew}}</v-icon></router-link>
              </td>
              <td>{{getReadableDate(post.created_at)}}</td>
              <td>{{getManagerNameById(post.created_by)}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import {mdiInformationOutline, mdiOpenInNew} from '@mdi/js'
import httpClient from '@/services/http.service'
import common from '@/components/mixins/common'

export default {
  name: 'AdWelcomePosts',
  components:{
    // editor: () => import('@/components/posts/Editor')
  },
  mixins: [common],
  data(){
    return{
      colors,
      mdiInformationOutline, mdiOpenInNew,
      posts: [],
    }
  },
  created() {
    httpClient.get('/ad-welcomes').then(({data}) =>{
      this.posts = data
    })
  },

}
</script>

<style scoped>

</style>
