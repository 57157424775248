import moment from 'moment'

export default {
  data() {
  },
  created() {

  },
  methods:{
    getManagerNameById(id){
      id = String(id)
      return this.$store.state.users.find(x=>x.id===id).name
    },
    getReadableDate(date){
      return moment(date).format('DD.MM.YY H:mm')
    }
  }
}
